body {
    background-color: #F8F8F8 !important;
    // background-color: #fff !important;
    // font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif !important;
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}
.p-6 {
    padding: 5rem !important;
}

.px-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}

.cursor {
    cursor: pointer;
}

.button-radius-home {
    border-radius: 30px !important;
}

.product-card-height {
    // height: 28.125rem !important;
    // width: 16.25rem !important;
    display: inline-block !important;
}

.product-card-height2 {
    width: 280px;
    display: inline-block !important;
}

.product-card-height-guide {
    width: 280px;
    height: 280px;
    display: inline-block !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.bo-plan {
    margin-top: 4rem !important;
}

.lh-1 {
    line-height: 0 !important;
}

.lh-2 {
    line-height: 1.5 !important;
}

.footer {
    clear: both;
    position: relative;
    bottom: 0;
    margin-top: auto;
    width: 100%;
}

.img-product {
    height: 275px;
    width: 275px;
}

.price-text {
    font-size: 55px !important;
}

.text-desc {
    font-size: 13px !important;
}

.product-desc {
    margin-left: -16px !important;
    margin-right: -16px !important;
}

.product-card {
    width: 350px !important;
    display: inline-block !important;
}

.button-radius {
    border-radius: 15px !important;
}

.bg-gradient-primary {
    background: linear-gradient(114.23deg, #53006A 11.21%, #9200BA 185.14%);
}

.btn-gradient-primary {
    background: linear-gradient(135deg, #53006A 0%, #9200BA 100%);
}

.fw-extrabold {
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 800;
    font-style: normal;
}

.fw-semibold {
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 600;
    font-style: normal;
}

.text-link-color {
    color: #745EFD !important;
}

@media only screen and (max-height: 800px) and (max-width :1024px) {
    .img-product {
        height: 300px !important;
        width: 200px !important;
    }
}

@media only screen and (min-height: 600px) and (max-width :800px) {
    img.d-block.w-100 {
        height: auto;
        max-height: 300px !important;
        object-fit: fill;
    }
}

@media only screen and (min-height: 600px) and (min-width :1024px) {
    img.d-block.w-100 {
        height: auto;
        max-height: 450px !important;
        object-fit: fill;
    }
}

@media only screen and (min-height: 900px) and (min-width :1024px) {
    img.d-block.w-100 {
        height: auto;
        max-height: 600px !important;
        object-fit: fill;
    }
}

@media only screen and (min-height: 1440px) and (min-width :2000px) {
    img.d-block.w-100 {
        height: auto;
        max-height: 650px !important;
        object-fit: fill;
    }
}